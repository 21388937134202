import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { TextWrapper } from "../../layout/reusables"
import { BsArrowRight } from "react-icons/bs"
import { Link } from "gatsby"

export default function StoryItem({ story, fromDb }) {
    return (
        <Link style={{ textDecoration: "none" }} to={fromDb ? `/we-phealt-that/${story.id}` : `/we-phealt-that/${story.link}`}>
            <Container>
                <div>
                    {fromDb ?
                    <Image src={story.image}/>
                    :
                    <Img
                        fluid={story.image}
                        style={{
                            width: "100%",
                            height: "100%",
                        }}
                    />
                    }
                </div>
                <Content>
                    <TextWrapper>
                        <Title>{story.title}</Title>
                        <Description dangerouslySetInnerHTML={{__html: story.description}} />
                        <BsArrowRight size={50} />
                    </TextWrapper>
                </Content>
            </Container>
        </Link>
    )
}

const Container = styled.div`
    display: grid;
    grid-template-columns: 40% 1fr;
    align-items: center;
    gap: 4%;
    color: black;
    margin-top: 30px;

    @media (max-width: 750px) {
        grid-template-columns: 1fr;
        margin-bottom: 50px;
    }

`
const Image = styled.img`
    width: 100%;
    max-height: 87vh;
    object-fit: cover;
`
const Content = styled.div``
const Title = styled.h2`
    font-size: 35px;
    margin-bottom: 30px;
    font-family: 'Quattrocento';
    font-weight: 400;
    max-width: 25ch;

    @media (max-width: 550px) {
        font-size: 28px;
    }
`
const Description = styled.div`
    font-size: 18px;
        line-height: 1.4;
        color: black;
    
    p {
        font-size: 18px;
        line-height: 1.4;
        color: black;

    }
    margin-bottom: 30px;
`
