import React from "react"
import styled from "styled-components"
import { graphql, Link } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Header from "../../components/we-phealt-that/header"
import StoryItem from "../../components/we-phealt-that/story-item"
import { ContentWrapper, Wrapper } from "../../layout/reusables"
import { colors } from "../../styles/colors"
import { DigestiveText } from "../../styles/typography"

const StickyCta = styled.div`
    position: fixed;
    right: 0;
    bottom: 20vh;
`
const ShareButton = styled.button`
    background: black;
    color: ${colors.yellow};
    text-align: center;
    text-transform: uppercase;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    padding: 15px 30px;
    border: none;
    outline: none;
    cursor: pointer;

    :focus {
        outline: none;
    }

    :disabled {
        background: #707070;
    }
`

const WePhealtThat = ({ data }) => {
    const { story, storyData, cyanStory } = data
    const phealStory = {
        title: story.title,
        description:
            "PHEAL is an educational platform designed to rethink how we approach learning in the modern world. A genuine educational experience takes our world and breaks it open, giving us the space for an honest exploration of our society and culture where no topic is too taboo and every voice has a chance to be heard.",
        image: story.featuredImage.node.localFile.childImageSharp.fluid,
        link: "our-story"
    }
    
    const cyanStoryData = {
        title: cyanStory.title,
        description: cyanStory.excerpt,
        image: cyanStory.featuredImage.node.localFile.childImageSharp.fluid,
        link: "this-is-my-story"
    }
    const stories = storyData.edges
        .filter(el => el.node.approved)
        .map(story => ({
            id: story.node.id,
            title: story.node.title,
            description: `${story.node.text.substring(0, 140)}...`,
            image: story.node.photos[0],
        }))

    return (
        <Layout dark>
            <SEO title="We Phealt That" />
            <Wrapper>
                <Header />
                <ContentWrapper id="stories" style={{ padding: "4vh 20px" }}>
                    <DigestiveText style={{textAlign: 'center'}} fontSize={70} mBottom={50}>Stories</DigestiveText>
                    {stories.map((story, index) =>  (
                            <StoryItem key={index} story={story} fromDb />
                    ))}
                    <StoryItem story={cyanStoryData} />
                    <StoryItem story={phealStory} />
                </ContentWrapper>
                <StickyCta>
                    <Link to="/we-phealt-that/create">
                        <ShareButton>Share Your Story</ShareButton>
                    </Link>
                </StickyCta>
            </Wrapper>
        </Layout>
    )
}

export default WePhealtThat

export const query = graphql`
    query MyQuery {
        wpPage(title: { eq: "We Phealt That" }) {
            title
            we_phealt_that_page {
                subtitle
                intro
                headline
                description
                cta
            }
        }
        story: wpPage(id: { eq: "cG9zdDo0Mjk=" }) {
            title
            content
            featuredImage {
                node {
                    localFile {
                        childImageSharp {
                            fluid(maxWidth: 1200, quality: 100) {
                                ...GatsbyImageSharpFluid_noBase64
                            }
                        }
                    }
                }
            }
        }
        storyData: allWePhealtThat(sort: {fields: createdAt, order: DESC}) {
            edges {
                node {
                    id
                    title
                    text
                    photos
                    approved
                }
            }
        }
        cyanStory: wpPost(id: { eq: "cG9zdDo1MDI=" }) {
            title
            content
            excerpt
            featuredImage {
                node {
                    localFile {
                        childImageSharp {
                            fluid(maxWidth: 1200, quality: 100) {
                                ...GatsbyImageSharpFluid_noBase64
                            }
                        }
                    }
                }
            }
        }
    }
`
