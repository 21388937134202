import { motion } from "framer-motion"
import { navigate } from "gatsby"
import scrollTo from "gatsby-plugin-smoothscroll"
import React from "react"
import { BsChevronCompactDown } from "react-icons/bs"
import styled from "styled-components"
import { Flex, TextWrapper } from "../../layout/reusables"
import { OutlineButton } from "../../styles/buttons"
import { colors } from "../../styles/colors"
import { DigestiveText, HeadlineText } from "../../styles/typography"

export default function Header({ intro, heading, subtitle }) {
    return (
        <Container>
            <Flex
                style={{ height: "100%" }}
                direction="column"
                align="center"
                justify="center"
            >
                <TextWrapper style={{ maxWidth: 900 }}>
                    <Intro>We Phealt That</Intro>
                    <Heading noUppercase>
                        We all have a story,
                        <br />
                        but we all tell it differently.
                    </Heading>
                    <Subtitle>PEOPLE HERE EVOLVE A LOT</Subtitle>
                </TextWrapper>
                <OutlineButton onClick={() => scrollTo("#stories")} dark>
                    See all stories
                </OutlineButton>
                <motion.div
                    initial={{ opacity: 0, y: 0 }}
                    animate={{ opacity: 1, y: "70%" }}
                    transition={{
                        loop: Infinity,
                        yoyo: true,
                        duration: 2,
                        repeatDelay: 1.4,
                    }}
                >
                    <BsChevronCompactDown size={30} />
                </motion.div>
            </Flex>
        </Container>
    )
}

const Container = styled.div`
    text-align: center;
    background: ${colors.yellow};
    min-height: 65vh;
    max-height: 100vh;
    padding: 20vh 5vh;

    @media (max-width: 550px) {
        padding: 15vh 3vh 6vh;
        max-height: none;
    }

`
const Intro = styled.p`
    text-transform: uppercase;
    margin-bottom: 30px;
`
const Heading = styled(HeadlineText)`
    font-size: clamp(55px, 10vw, 100px);
    margin-bottom: 30px;
`
const Subtitle = styled.p`
    text-transform: uppercase;
    margin-bottom: 30px;
`
